import React from "react"
import { Link } from "gatsby"
import classNames from 'classnames'
import ScheduleADemoButton from '../components/schedule-a-demo-button'
import Img from "../components/image-transform"

const GetStarted = ({content}) => {
  return (
    <div className="section-get-started">
      <div className="container">
        <div className="content-wrapper">
          <div className="text-holder">
            <h2 className="title">{content.title}</h2> 
            <p className="description">{content.description}</p> 
          </div>

          <div className="video-holder">
            {/* temporary */}
            <div className="video">
              {
                content.svg ?
                  <img src={content.image} className="video-link" alt={content.imageName}/>
                 :
                 <Img filename={content.image} className="video-link" alt={content.imageName}/>    
              }
              <p className="adoric_video_gettingstartedchallenge video-details">{content.videoDetails}</p>
            </div>
          </div>
          <div className="button-holder">
            {
              content.scheduleButton ?
              <ScheduleADemoButton bigButton="true" buttonText={content.buttonText} buttonColor={content.buttonColor}/>
              :
              content.isExternal ?
              <a href={content.url} target="_blank" rel="noopener noreferrer" className={classNames('btn', 
                {"btn-orange" : content.buttonColor === "orange"},
                {"btn-black" : content.buttonColor === "black"}
              )}> {content.buttonText}</a>
              :
              <Link to={content.url} className={classNames('btn', 
                {"btn-orange" : content.buttonColor === "orange"},
                {"btn-black" : content.buttonColor === "black"}
              )}> {content.buttonText}</Link>
            }
           
          </div>
        </div>
      </div>
    </div>
  )
}

export default GetStarted