class ContentChecker {
  nullChecker = value => {
    if (value === null) {
      return '';
    }

    if (value !== null) {
      return value;
    }

  }
}

export default new ContentChecker()