import React from "react"
import {graphql} from "gatsby"
import SEO from "../components/seo"
import { Helmet } from 'react-helmet'
import Layout from "../components/layout"

import FreeTrial from '../components/free-trial'
import TwoColumn from "../components/two-column"
import ComparisonTable from "../components/comparison-table"
import Testimonial from '../components/testimonial'
import SwiperTabs from '../components/swiper-tabs'
import PlanAction from '../components/plan-action'
import CustomerJourney from '../components/customer-journey'
import GetStarted from '../components/get-started'
import {
  comparisonTableContent,
  customerJourneyContent,
  developersContent,
  getStartedContent
} from "../lib/home-content"
import { swiperTabsContent } from "../lib/swiper-tabs-content"
// import { testimonialContent } from "../lib/testimonial-content"

import content from '../helpers/contentChecker'


const Personalised = ({data}) => {
  const bannerContent = {
    title: <>Here's why companies like {content.nullChecker(data.gcms.caseStudy.companyName)} rely on Billsby for easy and accurate recurring payments. </>,
    description: [
      {
        text: 'The most powerful, customizable and easy to integrate subscription billing software used by hundreds of companies worldwide to simplify revenue operations.'
      }
    ],
    withPromotion: true,
    promotionBadge: "OFFER",
    promotionTitle: "Start building your subscription business with a $5,000 free trial of Billsby when you sign up today",
    textColor: "#fff",
    backgroundColor: "#243F5C",
    backgroundImage: require('../images/home-hero.png'),
    image: 'home-hero.png',
    srcSet:'home-hero@2x.png 2x',
    imageName: 'billsby banner image',
    imagePosition: 'full-right',
    sectionHomeBanner: true,
    hasBg: true,
    buttons: [
      {
        url: 'https://app.billsby.com/registration',
        buttonText: 'Sign up for free',
        buttonColor: 'orange',
        isExternal: true
      },
      {
        scheduleButton: true
      }
    ]
  }

  const swiperTitle = {
    title: <>{content.nullChecker(data.gcms.caseStudy.companyName)} dreamt it, we bill it.</>
  }

  const planActionContent = {
    title: <>{content.nullChecker(data.gcms.caseStudy.companyName)} use Plan Action to keep customers connected</>,
    description: "Once customers sign-up and as their subscription changes, use Plan Actions to complete follow up tasks across all of the other tools and services you use to run your business.",
    image: require('../images/plan-action.svg'),
    svg: true,
    imageName: 'plan action image',
    cards: [
      {
        image: 'plan-action-1.png',
        srcSet:'plan-action-1@2x.png 2x',
        text: 'Add the new customer to your CRM system',
        logo: require('../images/pipedrive.svg'),
        svgLogo: true,
        logoSrcSet: '',
        logoName: 'pipedrive'
      },
      {
        image: 'plan-action-2.png',
        srcSet:'plan-action-2@2x.png 2x',
        text: 'Subscribe them to the correct mailing list',
        logo: 'mailchimp.png',
        logoSrcSet: 'mailchimp@2x.png 2x',
        logoName: 'mailchimp'
      },
      {
        image: 'plan-action-3.png',
        srcSet:'plan-action-3@2x.png 2x',
        text: 'Post out their welcome pack',
        logo: 'shipwire.png',
        logoSrcSet: 'shipwire@2x.png 2x',
        logoName: 'shipwire'
      }
    ],
    url: '/product/integrations/planactions',
    buttonColor: 'blue',
    buttonText: 'View all Plan Actions',
    comingSoonText:'Coming Soon'
  }

  const trialContent = {
    title: <>{content.nullChecker(data.gcms.caseStudy.companyName)} started with a free trial. Now you can too.</>,
    description: "When you try Billsby for yourself, you'll make your first $5,000 of subscription revenue on us - and you don't even need a credit card to get started. The best way to get to know Billsby is to try it for yourself.",
    svg: true,
    image: require("../images/free-trial.svg"),
    srcSet:'free-trial@2x.png 2x',
    imageName: 'free-trial image',
    buttons: [
      {
        url: 'https://app.billsby.com/registration',
        buttonColor: 'orange',
        buttonText: 'Get started with your free trial account',
        isExternal: true
      },
      {
        scheduleButton: true,
        buttonColor: 'black'
      }
    ]
  }
  

  return(
    <Layout isPoweredPage className="powered-page"> 
      <SEO title="Sales tax and compliance" />
      <Helmet>
        <title>{content.nullChecker(data.gcms.caseStudy.companyName)}</title>
      </Helmet>

      <TwoColumn content={bannerContent}/>
      <SwiperTabs 
        tabs={swiperTabsContent} 
        title={swiperTitle.title} 
        description="Every Billsby account supports unlimited products, plans and cycles - making it really easy to support even the most complex plans." 
      />
      <PlanAction content={planActionContent} />
      <CustomerJourney content={customerJourneyContent} />
      <ComparisonTable content={comparisonTableContent}/>
      <TwoColumn content={developersContent}/>
      <GetStarted content={getStartedContent} />
      <Testimonial />
      <FreeTrial content={trialContent}/>
    </Layout>
  )
}

export default Personalised

export const query = graphql`
query poweredTemplate($caseStudyId: ID!) {
  gcms {
    caseStudy(where: {id: $caseStudyId}) {
      companyName
    }
  }
}
`